import React from 'react'

import Hero from '../Components/Hero/Hero'
import Details from '../Components/Details/Details'
import MainBanner from '../Components/Banner/MainBanner'
import PickupBanner from '../Components/Banner/PickupBanner'
import Form from '../Components/Form/Form'
import ClientCarousel from '../Components/Clients/ClientsCarousel'
import WhatsApp from '../Components/WhatsApp/WhatsApp'
import Footer from '../Components/Footer/Footer'

const Home = () => {
    return(
        <>
            <Hero/>
            <Details/>
            <MainBanner/>
            <PickupBanner/>
            <ClientCarousel/>
            <Form/>
            <WhatsApp/>
            <Footer/>
        </>
    )
}

export default Home
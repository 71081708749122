import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Progress from '../Progress/Progress'

import {Typography}  from '@material-ui/core'

import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    formSectionWrapper: {
        backgroundColor: '#141456',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '4% 0',
        marginTop: '2%'
    },
    formWrapper: {
        width: '600px',
        backgroundColor: 'white',
        padding: theme.spacing(6),
        borderRadius: '30px',
        [theme.breakpoints.down('md')]: {
            width: '340px',
            padding: theme.spacing(2),
        },
    },
    sectionText: {
        maxWidth: 500,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'white',
        fontSize: '18px',
        marginBottom: theme.spacing(5)
    },
}))

const Form = () => {
    const classes = useStyles()
    return(
        <section id='form' className={classes.formSectionWrapper}>
            <Typography className={classes.sectionText}>Preencha o formulário abaixo e um dos nossos especialistas em terceirização de frota irá entrar em contato e fazer uma cotação gratuita.</Typography>
            <div className={classes.formWrapper}>
                <HubspotForm
                    portalId='8431937'
                    formId='71103ed9-f567-4795-8c28-454017634079'
                    onSubmit={() => console.log('Submit!')}
                    onReady={(form) => console.log('Form ready!')}
                    loading={<Progress/>}
                />
            </div>
        </section>
    )
}

export default Form 
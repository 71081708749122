import React from 'react'

import { makeStyles,Grid, Typography, Button } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    rowWrapper:{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    bannerWrapper: {
        backgroundColor: '#141456',
        color: '#fff',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '4% 0',
        maxWidth: '1000px',

        borderRadius: '20px',
        [theme.breakpoints.down('md')]: {
            maxWidth: '340px',
        }
    },
    textBanner: {
        fontSize: '22px',
        textAlign: 'center',
        padding: '4%'
    },
    customButton: {
        width: '400px',
        height: '50px',
        color: '#000',
        fontWeight: 'bold',
        backgroundColor: '#25DE4E',
        '&:hover': {
            backgroundColor: '#EC6B11',
        },
        borderRadius: '25px',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            maxWidth: '320px',
        }
    }
}))

const MainBanner = () => {
    const classes = useStyles()
    return(
        <Grid className={classes.rowWrapper} container>
            <Grid className={classes.bannerWrapper} item>
                <Typography variant='h4' className={classes.textBanner}>Estamos prontos para atender com eficiência, de acordo com a necessidade de sua empresa: marca e modelo de veículo, acessórios, localização geográfica...</Typography>
                <Button href='#form' variant='contained' className={classes.customButton}>Quero uma cotação gratuita</Button>
            </Grid>
        </Grid>
    )
}

export default MainBanner
import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class AutoPlay extends Component {
  render() {
    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 8000,
        autoplaySpeed: 100,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    return (
      <Slider {...settings}>
        <div>
          <h3><img src={require('../../assets/logo-sete.png')} alt='Logo Sete Soluções de Tecnologia'/></h3>
        </div>
        <div>
          <h3><img src={require('../../assets/logo-mgseg.png')} alt='Logo Sete Soluções de Tecnologia'/></h3>
        </div>
        <div>
          <h3><img src={require('../../assets/logo-sgs.png')} alt='Logo Sete Soluções de Tecnologia'/></h3>
        </div>
        <div>
          <h3><img src={require('../../assets/logo-life-saneamento.png')} alt='Logo Sete Soluções de Tecnologia'/></h3>
        </div>
        <div>
          <h3><img src={require('../../assets/logo-geo-sondar.png')} alt='Logo Sete Soluções de Tecnologia'/></h3>
        </div>
        <div>
          <h3><img src={require('../../assets/logo-systra.png')} alt='Logo Sete Soluções de Tecnologia'/></h3>
        </div>
      </Slider>
    );
  }
}

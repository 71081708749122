import React from 'react'

import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        position: 'fixed',
        bottom: '30px',
        right: '30px',
        borderRadius: '40%',
        padding: '10px',
        backgroundColor: '#00e676',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.5)',
        cursor: 'pointer',
        scale: 1,
        '&:hover': {
            scale: 1.1,
        },
    },
    icon: {
        fontSize: '60px',
        color: 'white'
    },
}))

const WhatsApp = () => {
    const classes = useStyles()

    return(
        <a rel='noopener noreferrer' href='https://wa.me/553197971650?text=Olá%20gostaria%20de%20saber%20mais%20sobre%20terceirização%20de%20frota%20com%20a%20Pontual' target="_blank"  className={classes.buttonWrapper}>
            <WhatsAppIcon className={classes.icon}/>
        </a>
    )
}

export default WhatsApp
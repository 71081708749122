import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap:'nowrap',
    alignItems: 'center',
    color: '#ec6b11'
  },
}));

const Progress = () => {
    const classes = useStyles()

    return(
        <div className={classes.root}>
            <CircularProgress/>
            <div>Carregando...</div>
        </div>
    )
}

export default Progress
import React from 'react'

import {Grid, Typography, Button} from '@material-ui/core'

import logoPontual from '../../assets/logo-pontual.png'
import pickup from '../../assets/hilux_prata.png'

import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    heroWrapper: {
        
    },
    mainText: {
        fontSize: '30px',
        color: '#141456',
        fontWeight: 'bold',
        [theme.breakpoints.down('lg')]: {
            fontSize: '24px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '22px',
        }
    },
    seconText: {
        fontSize: '20px',
        color: '#141456',
        maxWidth: 700,
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('lg')]: {
            fontSize: '18px',
            maxWidth: 500
        }
    },
    customButton: {
        width: '350px',
        [theme.breakpoints.up('lg')]: {
            width: '400px',
        },
        height: '60px',
        color: '#000',
        backgroundColor: '#25DE4E',
        '&:hover': {
            backgroundColor: '#EC6B11',
        },
        borderRadius: '25px',
        fontWeight: 'bold',
        boxShadow: '5px 5px rgba(0, 0, 0, 0.30)',
        marginTop: theme.spacing(6)
    },
    logo: {
        height: '70px',
        marginBottom: theme.spacing(5)
    },
    left: {
        paddingTop: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(1),
        },
        paddingLeft: theme.spacing(8)
    },
    blue: {
        height: 0,
        width: '10%',
        position:'absolute',
        borderStyle: 'solid',
        right: '0',
        top: '0',
        borderWidth: '0 0 700px 700px',
        borderColor: 'transparent transparent #141456 transparent',
        [theme.breakpoints.down('lg')]: {
            width: '10%',
            borderWidth: '0 0 500px 500px',
        }
    },
    orange: {
        position:'absolute',
        borderStyle: 'solid',
        top: '0',
        right: '0',
        borderWidth: '0px 0px 700px 700px',
        borderColor: 'transparent transparent #ec6b11 transparent',
        [theme.breakpoints.down('lg')]: {
            borderWidth: '0px 0px 500px 500px',
        }
    },
    rightSide: {
        maxWidth: '100%',
        [theme.breakpoints.up('xl')]: {
            height: 700
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    pickup: {
        width: 1000,
        position: 'absolute',
        right: '90px',
        top: '60px',
        [theme.breakpoints.down('lg')]: {
            width: 700,
            top: '50px',
            right: '30px',
        },
        [theme.breakpoints.down('md')]: {
            width: 600,
            top: '50px',
            right: '30px',
        }
    },
    buttonWrapper: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center'
        }
    },
    mark: {
        color: '#ec6b11'
    }
}))


const Hero = () => {
    const classes = useStyles()

    return(
        <Grid container className={classes.heroWrapper}>
            <Grid item sm={12} md={6} className={classes.left}>
                <img src={logoPontual} alt='Logo Pontual' className={classes.logo}/>
                <Typography  className={classes.mainText} variant='h1'>Terceirize sua frota <span className={classes.mark}>4x4</span> com a Pontual.<br/> Veículos novos personalizados de acordo com as necessidades da sua empresa.<br/> Rápido e sem burocracia!</Typography>
                <Typography  className={classes.seconText}variant='h2'>Atendimento personalizado: entendemos suas demandas e adaptamos nossos veículos para atender 100% as necessidades de sua empresa.</Typography>
                <div className={classes.buttonWrapper}><Button href='#form' className={classes.customButton} variant='contained' disableElevation>Quero uma cotação gratuita</Button></div>
            </Grid>            
            <Grid item  sm={12} md={6} className={classes.rightSide}>
                <div className={classes.blue}>
                    <div className={classes.orange}>
                        <img className={classes.pickup} src={pickup} alt='Camionete Pontual'/>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
export default Hero
import React from 'react'

import { Container, Grid, Typography } from '@material-ui/core'

import pickup from '../../assets/hilux_vermelha.png'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    bulletPoint: {
        color: '#141456',
        fontSize: '26px',
        fontWeight: 'bold',
        padding: '5px 0'
    },
    sectionTitle: {
        fontSize: '24px',
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(0)
        }
    },
    itemWrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(1)
    },
    itemIcon: {
        fontSize: 30, 
        paddingRight: 10,
        color: '#EC6B11'
    },
    imageColumn: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    image: {
        marginLeft: theme.spacing(8),
        width: '600px',
    },
    rowWrapper: {
        marginTop: theme.spacing(16),
        marginBottom: theme.spacing(16),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4   ),
        }
    },
    row: {
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(6)
        }
    }
}))

const PickupBanner = () => {
    const classes = useStyles()

    return(
        <React.Fragment >
            <Container className={classes.row}>
                <Typography className={classes.sectionTitle}>Quais tipos de empresa a Pontual atende?</Typography>
                <Grid container className={classes.rowWrapper}>
                    <Grid item>
                        <div className={classes.itemWrapper}>
                            <CheckCircleIcon className={classes.itemIcon}/><Typography className={classes.bulletPoint}>Mineradoras</Typography>
                        </div>
                        <div className={classes.itemWrapper}>
                            <CheckCircleIcon className={classes.itemIcon}/><Typography className={classes.bulletPoint}>Empresas de meio ambiente</Typography>    
                        </div>
                        <div className={classes.itemWrapper}>
                            <CheckCircleIcon className={classes.itemIcon}/><Typography className={classes.bulletPoint}>Empresas de segurança</Typography>
                        </div>
                        <div className={classes.itemWrapper}>
                            <CheckCircleIcon className={classes.itemIcon}/><Typography className={classes.bulletPoint}>Construtoras</Typography>
                        </div>
                        <div className={classes.itemWrapper}>
                            <CheckCircleIcon className={classes.itemIcon}/><Typography className={classes.bulletPoint}>Empreiteiras</Typography>
                        </div>
                    </Grid>
                    <Grid item className={classes.imageColumn}>
                        <div><img src={pickup} alt='pickup da frota Pontual' className={classes.image}/></div>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default PickupBanner
import React from 'react'

import {Grid, Typography} from '@material-ui/core'

import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    footerWrapper: {
        backgroundColor: '#EC6B11',
        textAlign: 'center',
        padding: '5% 0',
        color: 'white',
        [theme.breakpoints.down('md')]: {
            padding: '20% 0',
        }
    }
}))

const Footer = () => {
    const classes = useStyles()
    return (
        <Grid className={classes.footerWrapper}>
            <Typography>Pontual 2020 - todos os direitos reservados</Typography>
        </Grid>
    )
}

export default Footer
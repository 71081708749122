import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import Home from './Pages/Home'
import ThankYou from './Pages/ThankYou';

ReactDOM.render(
  <React.Fragment>
    <Router>
      <Switch>
        <Route path='/' exact={true}><Home/></Route>
        <Route path='/obrigado' exact={true}><ThankYou/></Route>
      </Switch>
    </Router>
  </React.Fragment>,
  document.getElementById('root')
);

import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import info from '../../assets/icon-info.png'
import hand from '../../assets/icon-handshake.png'
import car from '../../assets/icon-car.png'

import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    sectionWrapper: {
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(20),
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(10)
        }
    },
    sectionTitle: {
        textAlign: 'center',
        fontSize: '22px'
    },
    infoWrapper: {
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    detailColumn: {
        textAlign: 'center',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center'
    },
    textColumn: {
        maxWidth: '300px',
        marginTop: '40px',
        color: '#141456',
        fontSize: '18px'
    },
    break: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))

const Details = () => {
    const classes = useStyles()
    return(
        <React.Fragment>
            <div className={classes.sectionWrapper}>
                <Typography variant='h3' className={classes.sectionTitle}>Quais são as etapas para terceirizar sua frota<br className={classes.break}/> com a gente?</Typography>
                <Grid container className={classes.infoWrapper}>
                    <Grid item sm={12} md={4} className={classes.detailColumn}>
                        <img alt='icone de um carro' src={info}/>
                        <Typography  className={classes.textColumn}>Entendemos as demandas específicas de sua empresa. Entramos a fundo em seu negócio para conseguir melhor atendê-lo.</Typography>
                    </Grid>
                    <Grid item sm={12} md={4} className={classes.detailColumn}>
                        <img alt='icone de um carro' src={hand}/>
                        <Typography  className={classes.textColumn}>Fechamos um acordo de terceirização de frota personalizado: carros, acessórios, data de entrega, manutenção, suporte.</Typography>
                    </Grid>
                    <Grid item sm={12} md={4} className={classes.detailColumn}>
                        <img alt='icone de um carro' src={car}/>
                        <Typography className={classes.textColumn}>Entregamos sua frota com agilidade e qualidade além de um suporte ao cliente personalizado.</Typography>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default Details
import React from 'react'

import { makeStyles, Grid, Typography, Button, Container, Box } from '@material-ui/core'

import logo from '../assets/logo-pontual.png'

const useStyles = makeStyles((theme) => ({
    wrapp: {
        height: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10%'
    },
    rowWrapper:{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    bannerWrapper: {
        backgroundColor: '#141456',
        color: '#fff',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '4% 0',
        maxWidth: '1000px',

        borderRadius: '20px',
        [theme.breakpoints.down('md')]: {
            maxWidth: '340px',
        }
    },
    textBanner: {
        fontSize: '22px',
        textAlign: 'center',
        padding: '4%'
    },
    customButton: {
        width: '400px',
        height: '50px',
        color: '#000',
        fontWeight: 'bold',
        backgroundColor: '#25DE4E',
        '&:hover': {
            backgroundColor: '#EC6B11',
        },
        borderRadius: '25px',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            maxWidth: '320px',
        }
    },
    logoWrapper: {
        margin: '20px 0'
    }
}))

const ThankYou = () => {
    const classes = useStyles()
    return(
        <Container className={classes.wrapp}>
            <Box className={classes.logoWrapper}>
                <img src={logo} alt='Logo Pontual'/>
            </Box>
            <Grid className={classes.rowWrapper} container>
                <Grid className={classes.bannerWrapper} item>
                <Typography variant='h4' className={classes.bannerTitle}>Obrigado!</Typography>
                    <Typography variant='h4' className={classes.textBanner}>Contato enviado com sucesso! Em breve alguém da nossa equipe entrará em contato com você!</Typography>
                    <Button href='/' variant='contained' className={classes.customButton}>Voltar à página principal</Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ThankYou